import React from "react"

export default ({ id, children }) => (
	<div
		id={
			id ? "main-site-container site-container__" + id : "main-site-container"
		}
		style={{
			height: "100%",
			width: "100%",
			display: "block",
			// maxWidth: "80vw",
			// padding: `0 1rem`,
		}}
	>
		{children}
	</div>
)
